.App {
  text-align: center;
  /* max-width: 90% !important; */
 
}
body {
/* background-image: url('./recursos/ZebraBackgroundmassuave.jpg')!important; */
/* background-size: 100%!important; */
background-repeat: no-repeat;
background-size: cover;
width: 100%;
height: 100%;

}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
label {
  display: block;
  margin-top: 10px;
}
.card.card-container{
  align-items: center !important;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
  align-items: center !important;
}
.card {
  background-color: rgba(144, 31, 49, 0.25) !important;
  padding: 1%;
  margin: 1em auto ;
  /* margin-top: 50px; */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  
}

.profile-img-card {
  width: 80% ;
  height: 80% ;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
/* Estilos para dispositivos móviles */
@media only screen and (max-width: 820px) {
  .card-container.card {
    width: 85%;
    max-width: none!important; /* Anulamos la máxima anchura establecida anteriormente */
  }
  .form-control {
    font-size: xx-large !important;
    padding: 0.5em !important;
  }
  .form-group {
    margin: 2em;
  }
  .profile-img-card{
    width: 99%;
    height: 99%;
    margin: -5em;
    display: block;
    border-radius: 50%;
  }
  .btn {
    font-size: xx-large !important;
    padding: 0.5em !important;
  }
  .card {
    width: 95%!important;
    margin-top: 1rem;
  }
}

.cebra {
  width: 20%;
  height: 20%;
  margin: 10px  auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
    max-height: fit-content !important;
}

.Datagrid-rotado {
  transform: rotate(305deg) !important;
  /* overflow: auto; */
  /* white-space: initial !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center;
  padding-top: 10px !important;
  padding-bottom: 10px !important; */
}
.anotacion {
  font-size: xx-small;
  color: deeppink;
}
.comentario {
  font-size: 0.8em;
  color: deeppink;
}
.rsdt-paginate {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

button.rsdt-paginate.button {
  font-family: inherit;
  font-size: 100%;
  padding: 0.5em 1em;
  color: #666 !important;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid #999;
  border: transparent;
  background-color: #f9f9f9;
  text-decoration: none;
  border-radius: 2px;
  display: inline-block;
  zoom: 1;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button.rsdt-paginate.button:not(:disabled) {
  cursor: pointer;
}

button.rsdt-paginate.button:hover:not(:disabled) {
  color: #444 !important;
  background-color: #dfdfdf;
}

button.rsdt-paginate.active {
  color: #333 !important;
  background-color: #e9e9e9;
}

.rsdt.rsdt-column-toggles {
  margin-bottom: 20px;
}

.rsdt.rsdt-column-toggles.toggle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 0;
}

.rsdt.rsdt-column-toggles.toggle>label {
  margin-left: 5px;
  cursor: pointer !important;
  cursor: hand !important;
  user-select: none;
}

.rsdt {
  box-sizing: border-box;
}

.rsdt *,
.rsdt *::before,
.rsdt *::after {
  box-sizing: inherit;
}

.rsdt.rsdt-container {
  overflow: auto;
}

.rsdt.rsdt-highlight {
  background-color: yellow;
}

.rsdt.rsdt-sortable {
  margin-left: 10px;
}

.rsdt.rsdt-sortable-icon::after {
  font-size: 20px;
  font-style: normal;
  content: '\21D5';
  cursor: pointer;
}

.rsdt.rsdt-sortable-asc::after {
  font-size: 20px;
  font-style: normal;
  content: '\2193';
  cursor: pointer;
}

.rsdt.rsdt-sortable-desc::after {
  font-size: 20px;
  font-style: normal;
  content: '\2191';
  cursor: pointer;
}

.lista-con-detalle {
  transition: all 0.3s ease-in-out;
}

.lista, .detalle {
  opacity: 1;
  transform: translateX(0);
}

.lista {
  display: block;
}

/* .detalle {
  display: none;
} */

.lista-con-detalle .lista {
  display: block;
}

.lista-con-detalle .detalle {
  display: block;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
  /* border: 1px red solid; */
  border-radius: 6%;
  /* height: 300px; */
  width: 99%;
}
.image2 {
  position: absolute;
  width: 35%;
  top: 65%;
  left: 55%;
  /* border: 1px green solid; */
}
.NavLinkDiv {
  display: block;
}
/* Estilo para hacer la tabla transparente */
.custom-table {
  background-color: transparent!important; /* Establece el fondo de la tabla como transparente */
}

/* Estilo para hacer las celdas de la tabla transparentes */
.custom-table .rdt_TableCell {
  background-color: transparent!important; /* Establece el fondo de las celdas como transparente */
}